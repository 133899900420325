/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap'); */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;1,400&display=swap');

body {
  background-color: #fafafa !important;
}

.disabled > * {
  cursor: not-allowed;
  pointer-events: none !important;
  opacity: 0.3;
  user-select: none;
}

.insurancetypeImage {
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

.textInput:invalid {
  border: none;
  outline: 2px solid red;
}

.header {
  position: sticky;
  top: 0px;
  z-index: 999;
  background-color: #fff;
}

.paginate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bolder;
  padding: 5px;
  list-style-type: none;
  color: #24282c;
}

.active-page {
  padding: 0px 10px;
  background: var(--chakra-colors-primary-500);
  border-radius: 5px;
  color: #fff;
}

.paginate-button {
  padding: 5px 8px;
  margin: 0 10px;
  padding: 5px 10px;
}

.page-item {
  padding: 0.5rem 1rem;
  display: block;
  font-size: var(--chakra-fontSizes-md);
}
